<template>
  <div
    data-aos="fade-up"
    data-aos-duration="900"
  >
    <div class="relative max-w-7xl pc:w-[90%] pc:mx-auto tablet:mb-[180px] tablet:mx-8 mobile:mx-4 mobile:mb-[145px]">
      <div class="pc:mt-16 tablet:mt-12 mobile:mt-8">
        <span 
          class="font-bold -tracking-[0.3] 
                pc:text-[36px] pc:leading-[1.33] pc:text-gray-700 
                tablet:text-4xl tablet:leading-[1.27] 
                mobile:text-gray-800 mobile:text-2xl mobile:leading-[1.27]"
        >
          자주 묻는 질문
        </span>
      </div>
      <div class="flex pc:flex-row pc:justify-between pc:mt-6 tablet:mt-5 mobile:flex-col mobile:justify-start mobile:mt-4">
        <div class="flex flex-row inputRadio">
          <div 
            v-for="item in badgeData" 
            :id="item.value" 
            :key="item.idx"
          >
            <label class="rounded-[22px] bg-gray-50 tablet:mx-2 mobile:mx-1.5">
              <input 
                class="hide" 
                type="radio" 
                :name="item.name" 
                :value="item.value" 
                :checked="item.checked"
              >
              <span 
                class="font-bold text-gray-500 txtRadio w-full
                  tablet:py-2.5 tablet:px-6 tablet:text-lg tablet:leading-[1.33]
                  mobile:py-2 mobile:px-4 mobile:text-sm mobile:leading-[1.29]" 
                @click="changeCategory(item.value)"
              >{{ item.label }}</span>
            </label>
          </div>
        </div>
        <div>
          <div
            class="flex ml-auto items-center border-[1px] rounded-[4px] border-gray-200 input-border
                  pc:mt-0 pc:w-[435px] pc:h-12
                  tablet:mt-6 tablet:h-11
                  mobile:mt-4 mobile:w-full mobile:h-[42px]"
          >
            <input 
              v-model="keyword" 
              class="
                  bg-transparent outline-none text-gray-400 w-full pl-4 input-keyword
                  tablet:text-base tablet:leading-[1.25]
                  mobile:text-sm mobile:leading-[1.29]
                  " 
              placeholder="키워드 검색" 
              type="text" 
              @input="getFilterList" 
              @keydown.enter="getFilterList"
            >
            <i 
              class="icon-search cursor-pointer tablet:text-[24px] tablet:pr-4 mobile:text-[22px] mobile:pr-3" 
              @click="getFilterList"
            />
          </div>
        </div>
      </div>
      <div class="pc:mt-4 tablet:mt-6 mobile:mt-4">
        <div v-if="filteredFaqList.length > 0">
          <div
            v-for="(item, idx) in filteredFaqList"
            :key="idx"
            class="border-b border-gray-200 py-7"
            data-aos="fade-up"
            data-aos-duration="900"
            data-aos-anchor-placement="top-bottom"
          >
            <div
              class="flex flex-row justify-between w-full question"
              :class="{ 'on' : item.isOpened }"
              @click="toggleQuestion(item.idx, item.isOpened)"
            >
              <div
                class="text-gray-800 font-bold hover:text-orange-500 flex flex-row
                      tablet:text-xl tablet:leading-[1.3] tablet:w-[calc(100%-20px)]
                      mobile:text-lg mobile:leading-[1.33] mobile:w-[calc(100%-16px)]
                "
                style="flex-grow: 1; word-break: keep-all;"
              >
                <div class="tablet:w-6 mobile:w-5">
                  <span>Q.</span>
                </div>
                <div class="tablet:w-[calc(100%-24px)] mobile:w-[calc(100%-20px)] break-keep">
                  <span v-html="styledKeyword(item.question)" />
                </div>
              </div>
              <div 
                class="my-auto tablet:w-5 tablet:h-5 mobile:w-4 mobile:h-4"
                :class="{ 'pb-[24px]' : item.isOpened, 'pt-[2px]' : !item.isOpened }"
              >
                <img 
                  class="rotation" 
                  :class="{ 'rotation-on' : !item.isOpened }" 
                  src="@/assets/svg/icon-arrow-up.svg" 
                  alt=""
                >
              </div>
              <!-- <i
                class="pt-2 pl-2 icon-arrow-down rotation"
                :class="{ 'rotation-on' : !item.isOpened }"
              /> -->
              <!-- <i
                v-if="!item.isOpened"
                class="pt-2 pl-2 icon-arrow-down"
              />
              <i
                v-else
                class="pt-2 pl-2 icon-arrow-up"
              /> -->
            </div>
            <div
              class="answer pt-7 w-full flex flex-row
                    pc:px-6
                    tablet:text-lg tablet:leading-[1.72] tablet:pr-8
                    mobile:text-base mobile:leading-[1.56]"
              :class="{ 'on' : item.isOpened }"
              style="word-break: keep-all;"
            >
              <div class="tablet:w-6 mobile:w-5">
                <span class="text-gray-700">A.</span>
              </div>
              <div class="tablet:w-[calc(100%-24px)] mobile:w-[calc(100%-20px)] break-keep">
                <span
                  class="text-gray-700 break-keep"
                  v-html="styledKeyword(item.answer)"
                />
              </div>
              <!-- <button
                v-if="item.button"
                class="text-orange-500 underline"
                @click="showInterview"
              >
                경영진 인터뷰
              </button> -->
            </div>
          </div>
        </div>
        <div
          v-else
          class="w-full font-Pretendard"
        >
          <div class="relative text-center mt-[164px] mb-[188px]">
            <i class="icon-cook2 text-[100px] text-gray-200" />
            <div 
              class="text-3xl text-[#bebebe] mt-4"
            >
              검색 결과가 없습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqLists',
  data() {
    return {
      filteredFaqList: [],
      badgeData: [
        // {
        //   idx: 0,
        //   label: 'ALL',
        //   value: 0,
        //   checked: true,
        // },
        {
          idx: 1,
          label: '지원 문의',
          value: 1,
          checked: true,
        },
        {
          idx: 2,
          label: '채용 과정 문의',
          value: 2,
          checked: false,
        },
        {
          idx: 3,
          label: '기타 문의',
          value: 3,
          checked: false,
        },

      ],
      searchCategory: 1,
      filteredBadgeData: [],
      keyword: '',
      swiperOption: {
        slidesPerView: 'auto',
        preventClicks: true,
        preventClicksPropagation: false,
        observer: true,
        observeParents: true,
        loop: false,
        freeMode: true,
      },
      faqs: [
        {
          idx: 1,
          category: 1,
          question: '지원 시 유의사항은 무엇인가요? ',
          answer: '빠른 전형 안내를 위해 <b>이력서 및 포트폴리오</b>를  꼭 제출 부탁드려요. <br />파일 호환이 안되는 경우가 있어 <b>hwp</b> 파일 형식은 지양해 주세요.',
          isOpened: false
        },
        // {
        //   idx: 2,
        //   category: 1,
        //   question: '지원서가 정상적으로 제출되었는지 어떻게 확인할 수 있나요?',
        //   answer: '지원서를 제출하시면 기재한 메일 주소로 제출 완료 메일을 드리고 있어요. <br /> 혹시 제출 완료 메일을 받지 못하셨을 경우 <a href="mailto:recruit@cookapps.com"><u class="text-orange-500">recruit@cookapps.com</u></a> 으로 문의하시면 빠르게 도와드릴게요.',
        //   isOpened: false
        // },
        {
          idx: 3,
          category: 1,
          question: '지원서 제출 후, 추가 서류 업데이트하려면 어떻게 해야 하나요?',
          answer: '추가 서류 제출은 <a href="mailto:recruit@cookapps.com"><u class="text-orange-500">recruit@cookapps.com</u></a>으로 지원 포지션, 성함과 함께 서류를 보내주시면 채용팀에서 도와드릴 예정이에요.',
          isOpened: false
        },
        {
          idx: 4,
          category: 1,
          question: '불합격한 이력이 있어도 재지원 가능할까요?',
          answer: ' 스튜디오 운영 상황과 프로젝트의 성격에 따라 채용하고자 하는 역량이 달라질 수 있기 때문에 부담 없이 재지원해 주셔도 괜찮아요. 다만 동일한 포지션에 지원하는 경우에는 이력사항, 포트폴리오 등에 있어 변경된 부분이 없으시다면 다른 결과로 안내드리기 어려울 수 있는 점 양해 부탁드려요.',
          isOpened: false
        },
        {
          idx: 5,
          category: 1,
          question: '한 번에 여러 포지션 지원 가능할까요?',
          answer: '여러 포지션에 동시 지원이 가능하지만, 포지션 별로 서류 검토가 이루어지고 있어 평가 기준이 다소 다를 수 있습니다. 포지션 별 채용 과정과 기준 참고 부탁드려요.',
          isOpened: false
        },
        {
          idx: 6,
          category: 1,
          question: '학력, 나이에 제한이 있나요?',
          answer: '쿡앱스는 학력과 나이를 기준으로 채용을 진행하지 않아요. 다만 전문연구요원의 경우 이공계 석사 학위 소지를 하신 분이 신규 편입의 기본 조건이기 때문에 해당 자격 요건이 필요해요.',
          isOpened: false
        },
        {
          idx: 7,
          category: 1,
          question: '인턴의 경우 정규직 전환이 가능한가요?',
          answer: '슈퍼루키 인턴십을 제외한 인턴 채용의 경우 정규직 전환이 불가능해요. 자세한 내용은 인턴 채용 공고 참고 부탁드려요. ',
          isOpened: false
        },
        {
          idx: 8,
          category: 1,
          question: '인재풀에 등록할 수 있는 방법은 무엇인가요?',
          answer: '쿡앱스 채용팀 이메일 <a href="mailto:recruit@cookapps.com"><u class="text-orange-500">recruit@cookapps.com</u></a>으로 원하시는 포지션 및 이력서, 포트폴리오 등을 보내주시면 인재풀에 등록 후 해당 포지션 채용 진행 시 연락드릴게요.',
          isOpened: false
        },
        {
          idx: 9,
          category: 1,
          question: '서류 불합격 시 결과 안내받을 수 있나요?',
          answer: '합격/불합격 관계없이 모든 지원서류에 대해 결과 안내를 드리고 있어요.',
          isOpened: false
        },
        {
          idx: 10,
          category: 2,
          question: '서류 지원부터 최종 합격까지 전체 채용 프로세스 소요 기간은 어느 정도 걸리나요?',
          answer: '전형 기간의 경우 과제 유/무, 인턴 채용/정규직 채용에 따라 조금씩 다를 수 있으나 서류 접수부터 최종 합격까지 평균 2주~6주의 시간이 소요되고 있어요. 좋은 경험과 역량을 갖추고 계신 분이라면 언제든지 쿡앱스 가족으로 맞이할 준비가 되어있으니 많은 관심과 지원 부탁드려요.',
          isOpened: false
        },
        {
          idx: 11,
          category: 2,
          question: '채용 과정은 어떻게 진행되나요?',
          // answer: '쿡앱스 채용절차는  서류전형, 과제전형, 1차 실무진 면접, 2차 경영진 면접으로 구성되어 있어요. 포지션에 따라 채용 절차가 다르기 때문에 채용 공고에 기재된 내용을 확인해 주세요. <br />*면접 과정에 대한 자세한 내용은 <a href="https://www.cookapps.com/process"><u class="text-orange-500">https://www.cookapps.com/process</u></a>에서 확인하실 수 있어요.',
          answer: '쿡앱스 채용절차는  서류전형, 과제전형, 1차 실무진 면접, 2차 경영진 면접으로 구성되어 있어요. 포지션에 따라 채용 절차가 다르기 때문에 채용 공고에 기재된 내용을 확인해 주세요.',
          isOpened: false
        },
        {
          idx: 12,
          category: 2,
          question: '전형 결과는 언제 알 수 있나요?',
          answer: '모든 전형에 대한 결과는 7일 이내로 안내드리고 있어요.<br />별도 안내 없이 2주 이상 연락받지 못하셨을 경우, <a href="mailto:recruit@cookapps.com"><u class="text-orange-500">recruit@cookapps.com</u></a> 으로 메일 보내주세요. ',
          isOpened: false
        },
        {
          idx: 13,
          category: 2,
          question: '과제 제출은 어떻게 하나요?',
          answer: '과제 안내 메일에 있는 링크 클릭 후 제출해 주세요.<br />제출 시 오류가 있는 경우 <a href="mailto:recruit@cookapps.com"><u class="text-orange-500">recruit@cookapps.com</u></a>으로 메일 보내주시면 채용팀에서 도와드릴 예정이에요.',
          isOpened: false
        },
        {
          idx: 14,
          category: 2,
          question: '면접 일정은 어떻게 조율하나요?',
          answer: '서류 합격 혹은 과제 합격 시, 채용팀에서 지원자분께 1-2주 이내에 면접 가능한 일정을 확인 후 조율하고 있어요.',
          isOpened: false
        },
        {
          idx: 15,
          category: 2,
          question: '면접 일정을 변경할 수 있나요?',
          answer: '지원자분께서 조율된 일정에 면접이 힘드신 경우, 채용팀에 문의하시면 다시 일정 조율을 진행 예정이에요.',
          isOpened: false
        },
        {
          idx: 16,
          category: 2,
          question: '면접 소요시간이 어떻게 되나요?',
          answer: '대부분의 면접은 40분 ~ 1시간 이내로 진행하고 있어요.',
          isOpened: false
        },
        {
          idx: 17,
          category: 2,
          question: '면접 복장에 제한이 있을까요?',
          answer: '복장은 자율 복장이며, 면접관 분들도 편안한 캐주얼 복장으로 참여 예정이에요.',
          isOpened: false
        },
        {
          idx: 18,
          category: 2,
          question: '면접 확인서 발급은 어디서 신청할 수 있나요?',
          answer: '<a href="mailto:recruit@cookapps.com"><u class="text-orange-500">recruit@cookapps.com</u></a> 으로 이름, 지원 포지션, 발급 신청 사유를 보내주시면 채용팀에서 발급 도와드릴 예정이에요.',
          isOpened: false
        },
        {
          idx: 19,
          category: 3,
          question: '비포괄임금제 인가요?',
          answer: '2022년 7월부터 비포괄임금제를 시행하고 있으며, 승인받은 초과수당은 시급의 200%로 가산하여 지급하고 있어요.',
          isOpened: false
        },
        {
          idx: 20,
          category: 3,
          question: '인센티브가 있나요?',
          answer: '구성원분들의 노력에 보답하고자 연 2회 연봉협상 및 인센티브를 지급하고 있어요.',
          isOpened: false
        },
        {
          idx: 21,
          category: 3,
          question: '어떤 복지들이 있나요?',
          answer: '유연근무제, 대학원 학비 90%, 삼시 세끼 제공, 점심시간 2시간, 어학교육비 지원 등 많은 복지가 있어요. <br />자세한 내용은 스토리 참고 부탁드려요. <a href="https://www.cookapps.com/cookapps-story/122"><u class="text-orange-500">https://www.cookapps.com/cookapps-story/122</u></a>',
          isOpened: false
        },
      ]
    };
  },
  mounted() {
    this.searchCategory = 1;
    this.getFilterList();
  },
  methods: {
    changeCategory(value) {
      this.searchCategory = value;
      this.badgeData.forEach(item => {
        if(item.value===Number(this.searchCategory)) item.checked = true;
        else item.checked = false;
      });
      this.getFilterList();
    },
    getFilterList() {
      if (this.keyword === '') {
        this.filteredFaqList = this.faqs.filter(item => item.category === this.searchCategory);
      } else {
        this.filteredFaqList = this.faqs.filter(item => (item.category === this.searchCategory) && (item.question.includes(this.keyword) || item.answer.includes(this.keyword)));
      }
      this.badgeData.forEach(item => {
        if(item.value===Number(this.searchCategory)) item.checked = true;
        else item.checked = false;
      });
    },
    toggleQuestion(index, opened) {
      this.faqs.map(item => { return item.isOpened = false; });
      this.faqs.filter(item => item.idx === index)[0].isOpened = !opened;
    },
    styledKeyword(faqAlias) {
      const result = faqAlias.replace(this.keyword, `<span style="color: #ff7f00">${this.keyword}</span>`);
      return result;
    }
  },
};
</script>

<style lang="scss" scoped>
.inputRadio{
  position: relative;
  label{
    overflow: hidden;
    display: inline-block;
    width: auto;
    box-sizing: border-box;
    text-align: center;
    &:hover{
      cursor: pointer;
    }
    &:first-child{
      margin-left: 0 !important;
    }
  }
  .txtRadio{
    user-select: none;
    display: inline-block;
    text-align: center;
    width: 100%; height: 100%;
  }
  label > input:checked + .txtRadio{
      background: $primaryColor;
      color: #FFF;
  }
  label > input:not(:checked):hover + .txtRadio{
      background: #fff2e5;
      color: #ff7f00;
  }
}

.input-keyword {
  color: #444;
}
.input-keyword::placeholder {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.1px;
  color: #999999;
}

:is(.mobile) .input-keyword::placeholder {
  font-size: 14px;
}

.input-border:focus-within {
  border: solid 1px #333333;
}
.question{
  user-select: none;
  cursor: pointer;
  &.on div{
    color: $orange-500
  }
}
.answer{
  max-height: 0;
  overflow: hidden;
  height: auto;
  &:not(.on){
    padding: 0 !important;
  }
  &.on{
    max-height: 500px;
  }
}

.rotation {
  transition: all ease .3s;
}

.rotation-on {
  transform: rotate(180deg);
}
</style>